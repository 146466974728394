import { AngularSvgIconModule } from 'angular-svg-icon';
import { BrMaskerModule } from 'br-mask';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../../../core/shared/modules/components.module';
import { IonicModule } from '@ionic/angular';
import { ModalAccountActivationComponent } from './modal-account-activation/modal-account-activation.component';
import { ModalActiveCardComponent } from './modal-active-card/modal-active-card.component';
import { ModalAlertBilletComponent } from './modal-alert-billet/modal-alert-billet.component';
import { ModalAlertCardComponent } from './modal-alert-card/modal-alert-card.component';
import { ModalAlertComponent } from './modal-alert/modal-alert.component';
import { ModalBilletReviewChargingComponent } from './modal-billet-review-charging/modal-billet-review-charging.component';
import { ModalBilletReviewComponent } from './modal-billet-review/modal-billet-review.component';
import { ModalCardDocumentComponent } from './modal-card-document/modal-card-document.component';
import { ModalCardNotificationComponent } from './modal-card-notification/modal-card-notification.component';
import { ModalExtractReviewComponent } from './modal-extract-review/modal-extract-review.component';
import { ModalFlowComponent } from './modal-flow/modal-flow.component';
import { ModalOperationNotificationComponent } from './modal-operation-notification/modal-operation-notification.component';
import { ModalProsperaAttendanceComponent } from './modal-prospera-attendance/modal-prospera-attendance.component';
import { ModalProsperaBilletComponent } from './modal-prospera-billet/modal-prospera-billet.component';
import { ModalProsperaNotificationComponent } from './modal-prospera-notification/modal-prospera-notification.component';
import { ModalSearchBankComponent } from './modal-search-bank/modal-search-bank.component';
import { ModalSearchMccComponent } from './modal-search-mcc/modal-search-mcc.component';
import { ModalSharePage } from './modal-share/modal-share.page';
import { ModalTokenComponent } from './modal-token/modal-token.component';
import { ModalTransferNotificationAcceptedComponent } from './modal-transfer-notification-accepted/modal-transfer-notification-accepted.component';
import { ModalTransferNotificationCompletedComponent } from './modal-transfer-notification-completed/modal-transfer-notification-completed.component';
import { ModalTransferNotificationRefusedComponent } from './modal-transfer-notification-refused/modal-transfer-notification-refused.component';
import { ModalTransferResumeComponent } from './modal-transfer-resume/modal-transfer-resume.component';
import { ModalUnlockCardAlertComponent } from './modal-unlock-card-alert/modal-unlock-card-alert.component';
import { ModalUnlockCardComponent } from './modal-unlock-card/modal-unlock-card.component';
import { ModalPixManagementKeyComponent } from './modal-pix-management-key/modal-pix-management-key.component';
import { ModalPixComponent } from './modal-pix/modal-pix.component';
import { ModalsRoutingModule } from './modals-routing.module';
import { NgModule } from '@angular/core';
import { PopoverFavoritesComponent } from 'src/app/views/themes/sentinel/modals/popover-favorites/popover-favorites.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UiBsCardsModule } from '@wlp/ui-bs-cards';
import { UiBsEcModule } from '@wlp/ui-bs-ec';
import { UiBsTokenModule } from '@wlp/ui-bs-token';
import { UiBsTranslateModule } from '@wlp/ui-bs-translate';
import { UiCActiveCardModalFormModule } from '@wlp/ui-c-active-card-modal-form';
import { UiCAddCreditCardFormModule } from '@wlp/ui-c-add-credit-card-form';
import { UiCBilletReviewChargingModule } from '@wlp/ui-c-billet-review-charging';
import { UiCBilletReviewModule } from '@wlp/ui-c-billet-review';
import { UiCButtonModule } from '@wlp/ui-c-button';
import { UiCCardDocumentModule } from '@wlp/ui-c-card-document';
import { UiCCreditCardDisplayModule } from '@wlp/ui-c-credit-card-display';
import { UiCListDataModule } from '@wlp/ui-c-list-data';
import { UiCModalModule } from '@wlp/ui-c-modal';
import { UiCPaymentExtractModule } from '@wlp/ui-c-payment-extract';
import { UiCPaymentReceiptModule } from '@wlp/ui-c-payment-receipt';
import { UiCPdfGeneratorModule } from '@wlp/ui-c-pdf-generator';
import { UiCProsperaAddCreditCardFormModule } from '@wlp/ui-c-prospera-add-credit-card-form';
import { UiCProsperaAttendanceModule } from '@wlp/ui-c-prospera-attendance';
import { UiCProsperaBilletModule } from '@wlp/ui-c-prospera-billet';
import { UiCProsperaCreditCardDisplayModule } from '@wlp/ui-c-prospera-credit-card-display';
import { UiCSearchBankModule } from '@wlp/ui-c-search-bank';
import { UiCSearchMccModule } from '@wlp/ui-c-search-mcc';
import { UiCTextValueModule } from '@wlp/ui-c-text-value';
import { UiCTokenModule } from '@wlp/ui-c-token';
import { UiCTransferReceiptModule } from '@wlp/ui-c-transfer-receipt';
import { UiCPixReceiptModule } from '@wlp/ui-c-pix-receipt';
import { environment } from 'src/environments/environment';
import { ModalPdfGenerateComponent } from './modal-pdf-generate/modal-pdf-generate.component';
import { PopoverSaveOptionsComponent } from './popover-save-options/popover-save-options.component';
import { ModalCsvGenerateComponent } from './modal-csv-generate/modal-csv-generate.component';
import { FormImportFilesModalPage } from './import-files-form-modal/import-files-form-modal.page';
import { UiCUploadFileWebModule } from '@wlp/ui-c-upload-file-web';
import { ModalVirtualCardComponent } from './modal-virtual-card/modal-virtual-card.component';
import { ModalCombateAFraudeComponent } from './modal-combate-a-fraude/modal-combate-a-fraudecomponent';
import { encryptDataService } from '../functions/encrypt/encrypt.page';

@NgModule({
  declarations: [
    ModalAlertComponent,
    ModalSharePage,
    ModalBilletReviewComponent,
    ModalTransferNotificationAcceptedComponent,
    ModalTransferNotificationRefusedComponent,
    ModalTransferNotificationCompletedComponent,
    ModalTransferResumeComponent,
    ModalTokenComponent,
    ModalOperationNotificationComponent,
    ModalSearchBankComponent,
    ModalExtractReviewComponent,
    ModalProsperaNotificationComponent,
    ModalProsperaAttendanceComponent,
    ModalProsperaBilletComponent,
    ModalCardDocumentComponent,
    ModalCardNotificationComponent,
    ModalActiveCardComponent,
    ModalFlowComponent,
    ModalAlertCardComponent,
    ModalUnlockCardAlertComponent,
    ModalUnlockCardComponent,
    ModalBilletReviewChargingComponent,
    ModalAlertBilletComponent,
    ModalAccountActivationComponent,
    ModalPixManagementKeyComponent,
    PopoverFavoritesComponent,
    PopoverSaveOptionsComponent,
    ModalSearchMccComponent,
    ModalPixComponent,
    ModalPdfGenerateComponent,
    ModalCsvGenerateComponent,
    FormImportFilesModalPage,
    ModalVirtualCardComponent,
    ModalCombateAFraudeComponent
  ],
  imports: [
    AngularSvgIconModule.forRoot(),
    CommonModule,
    ComponentsModule,
    IonicModule,
    ModalsRoutingModule,
    ReactiveFormsModule,
    BrMaskerModule,
    UiBsCardsModule.forRoot({ device: 'WEB', config: environment }),
    UiBsTokenModule.forRoot({ device: 'WEB', config: environment }),
    UiBsTranslateModule.forRoot({ device: 'WEB', config: environment }),
    UiCBilletReviewModule,
    UiCListDataModule,
    UiCModalModule,
    UiCTextValueModule,
    UiCTokenModule,
    UiCSearchBankModule,
    UiCSearchMccModule,
    UiCTransferReceiptModule,
    UiCPaymentExtractModule,
    UiCPaymentReceiptModule,
    UiCPdfGeneratorModule,
    UiCProsperaAttendanceModule,
    UiCAddCreditCardFormModule,
    UiCCreditCardDisplayModule,
    UiCProsperaCreditCardDisplayModule,
    UiCProsperaAddCreditCardFormModule,
    UiCProsperaBilletModule,
    UiCCardDocumentModule,
    UiCActiveCardModalFormModule,
    UiCButtonModule,
    UiCBilletReviewChargingModule,
    UiBsEcModule.forRoot({ device: 'WEB', config: environment }),
    UiCPixReceiptModule,
    UiCUploadFileWebModule,
  ],
  entryComponents: [ModalTokenComponent, ModalCardNotificationComponent, ModalSearchMccComponent, FormImportFilesModalPage],
  providers: [
    encryptDataService
  ]
})
export class ModalsModule { }
